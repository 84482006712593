<template>
  <div class="Login">
    <div class="section">
      <div class="title">
        <img class="titleImg" src="../../../assets/login/logo.svg" alt="">
        <p class="titlelogin">Login to your ianypdf ID</p>
        <p class="titlegoR">
          No account? Go to <span style="color:#5452F6;cursor:pointer" @click="goRegister">register</span>
        </p>
      </div>
      <!-- 登陆表单 -->
      <el-form class="" :model="formData" :rules="rule" ref="form" style="margin-bottom: 8px;">
        <el-form-item label prop="UserName">
          <div class="left-style backEmi" autocomplete="auto" />
          <el-input placeholder="E-mail" v-model="formData.UserName" @blur="UserNameTrim" class="setlist"
            @input="checkEmail">
          </el-input>
        </el-form-item>
        <el-form-item label prop="UserPassword" class="pass">
          <div class="left-style backPass" autocomplete="auto" />
          <el-input style="position: relative" placeholder="Password" v-model="formData.UserPassword"
            :type="formData.isShowPassword ? 'text' : 'password'" @blur="UserPasswordTrim"
            :autocomplete="$store.state.isLoginExpired ? 'new-password' : ''">
          </el-input>
          <div class="eye-style" :class="[formData.isShowPassword ? 'eyes' : 'uneye']" autocomplete="auto"
            @click="formData.isShowPassword = !formData.isShowPassword" />
        </el-form-item>
      </el-form>
      <!-- 处理登录部分 -->
      <div v-if="formData.isSignIn" :class="formData.isOk ? 'signUpButton' : 'unsignUpButton'" @click="signIn"
        :disabled="formData.disable">
        Log in
      </div>
      <div v-else :class="formData.isOk ? 'signUpButton' : 'unsignUpButton'" :disabled="formData.disable">
        <div class="custom-loading">
          <svg class="circular" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd"
              d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM1.45459 8.00014C1.45459 11.6151 4.38509 14.5456 8.00004 14.5456C11.615 14.5456 14.5455 11.6151 14.5455 8.00014C14.5455 4.38519 11.615 1.45469 8.00004 1.45469C4.38509 1.45469 1.45459 4.38519 1.45459 8.00014Z"
              fill="white" />
            <path
              d="M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16V14.5455C4.38505 14.5455 1.45455 11.615 1.45455 8C1.45455 4.38505 4.38505 1.45455 8 1.45455V0Z"
              fill="white" />
          </svg>
          Log in
        </div>
      </div>
      <div class="buttom" @click="goReset">Retrieve password</div>
    </div>
  </div>
</template>

<script>
import tools from '@/utils/tools';
import moment from "moment";
import { gaEvent } from "@/utils/gaEvent";
export default {
  name: "Logins",
  data() {
    const validateUserNameASCII = (rule, value, callback) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      this.formData.UserName = value.replace(re, "")
      callback()
    }
    const validateUserPasswordASCII = (rule, value, callback) => {
      var re = /[^a-zA-Z0-9!"#$%&'()*+,.\\\/:;<=>?@\[\] ^_`{|}~-]/g;
      this.formData.UserPassword = value.replace(re, "")
      callback()
    }
    return {
      senMsg: 1,
      formData: {
        UserName: "",
        UserPassword: "",
        EmailCode: "",
        signInCount: 0,
        isShowPassword: false,
        isOk: false,
        flag: true,
        code: "",
        input1: false,
        inputPassword: false,
        // btnText: "Sign in",
        btnText: '111',
        isSignIn: true,
        disable: false,
      },
      rule: {
        UserName: [
          { validator: validateUserNameASCII, trigger: "change" },
          { required: true, message: 'Email address cannot be empty', trigger: 'blur' },
          {
            required: true,
            // pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Email is invalid',
            trigger: 'blur',
          },
          {
            required: true,
            pattern: /^\S{1,100}$/,
            message: 'Email is invalid', trigger: 'blur'
          },
        ],
        UserPassword: [
          { validator: validateUserPasswordASCII, trigger: "change" },
          { required: true, message: 'Password cannot be empty', trigger: 'blur' },
          {
            required: true,
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#_~?&^]{8,20}$/,
            message: 'Password must be 8-20 characters and a combination of letters and numbers.',
            trigger: 'blur',
          }
        ],
      },

    }
  },
  methods: {
    UserNameTrim() {
      console.log("-=-=-=-");
      this.formData.UserName = tools.LTrim(tools.RTrim(this.formData.UserName))
    },

    UserPasswordTrim() {
      this.formData.UserPassword = tools.LTrim(tools.RTrim(this.formData.UserPassword))
    },

    checkPassword() {
      console.log("检测密码");
      // unref 如果参数是一个 ref 则返回它的 value，否则返回参数本身。
      this.$refs["form"].validateField(["UserPassword"], errMsg => {
        if (errMsg) {
          console.log("errMsg:", errMsg);
          this.formData.inputPassword = false
          return false;
        } else {
          this.formData.inputPassword = true
          return true;
          console.log("errMsg111:", errMsg, this.inputPassword);
        }
      });
    },

    goRegister() {
      console.log("去注册");
      this.$store.commit("setModalStatus", "2")
      // context.emit("goRegister");
    },

    goReset() {
      console.log("去充值密码");
      this.$store.commit("setModalStatus", "3")
    },
    checkEmail() {
      this.$refs["form"].validateField(["UserName"], errMsg => {
        if (errMsg) {
          this.input1 = false
          return false;
        } else {
          this.input1 = true
          return true;
        }
      });
    },

    signIn() {
      // this.$store.state.loginTime = new Date();
      gaEvent("click_sign", {
        event_category: "Login_Register",
        event_label: this.$store.state.currentPage,
      });
      this.checkPassword()
      console.log("检测完成");
      console.log("formData:", this.formData);
      if (!this.formData.isOk) {
        console.log("isOk");
        return;
      }
      if (!this.formData.inputPassword) {
        console.log("inputPassword");
        return;
      }
      console.log("检测完成111");

      // if (!formData.UserName && !formData.UserPassword) return;
      if (this.formData.disable === false) {
        console.log("登录");
        this.formData.disable = true;
        // formData.btnText = "Logging in";
        this.formData.isSignIn = false;
        // 点击登录按钮后统计一次
        // event("Login_Button", {
        //   event_category: "LogRegister",
        //   event_label: "None",
        // });
        this.$http
          .post('/app/user/login', {
            email: this.formData.UserName,
            password: this.formData.UserPassword,
            device_id: localStorage.getItem('deviceId'),
            device_name: localStorage.getItem('pcusername')
          })
          .then(async (res) => {
            if (res.code == "200") {
              let data = new Date().getTime();
              // router.push({ path: "/" });
              localStorage.setItem("token", res.data.token);
              // test用
              localStorage.setItem("email", res.data.email);
              // 登录密钥
              localStorage.setItem("authorization", res.data.token);
              localStorage.setItem("userInfo", JSON.stringify(res.data));
              this.$store.commit("changeToken", res.data.token);
              console.log("登录成功");
              // 新接口返回的数据没有userInfo字段
              // 但是登录的时候有需要拿到数据，之前的将字段的id以及email都封装在userInfo中
              // 所以这里要都拿到数据就直接将所有数据都传递过去，因为反正是同层级的，之后要用哪个就直接取就好了
              this.$emit("loginResponseSuccess", res.data);
            } else {
              // this.$emit("loginResponseError", "登录失败");
            }
            console.log("then里面");
            this.formData.isSignIn = true;
          })
          .catch((err) => {
            // console.log("登录失败", err);
            console.log("catch里面");
            this.formData.disable = false;
            this.formData.isSignIn = true;
            gaEvent("sign_fail", {
              event_category: "Login_Register",
              event_label: err.response.data.code ? err.response.data.code : '500',
            });
            // // 错误提示
            if (err.response.data.code == "400201") {
              this.$message.error(
                "Email or password incorrect, please enter again"
              );
              this.formData.disable = false;
              this.formData.btnText = "Sign in";
              console.log("400201了");
            } else if (err.response.data.code == "401534") {
              this.$message({
                dangerouslyUseHTMLString: true,
                message:
                  "You have entered the wrong password 5 times. Your account is temporarily locked <br/>" +
                  "and cannot be logged in. Please try 10 mins later.",
                type: "error",
              });
              this.formData.disable = false;
              this.formData.btnText = "Sign in";
            } else if (err.response.data.code == "404001") {
              this.$message.error("The email address provided is not registered");
              this.formData.disable = false;
              this.formData.btnText = "Sign in";
            } else if (err.response.data.code == "400102") {
              this.$message.warning("Please check your email and make sure it is valid.");
              this.formData.disable = false;
              this.formData.btnText = "Sign in";
            } else if (err.response.data.code == "401524") {
              this.$emit("loginResponseSuccess", 5);

            } else {
              this.$message.error("Login failed!");
              this.formData.disable = false;
              this.formData.btnText = "Sign in";
            }
            let num = window.localStorage.getItem("signInErrCount") || "";
            localStorage.setItem("signInErrCount", num);
            // localStorage.setItem("signInErrCount", ++num);
            localStorage.setItem(
              "signInErrData",
              moment().format("YYYY-MM-DD")
            );
          });
      }
    },
  },

  watch: {
    'formData.UserName': function (val) {
      if (this.formData.UserName && this.formData.UserPassword) {
        this.formData.isOk = true
        console.log();
      } else {
        this.formData.isOk = false
      }
    },
    'formData.UserPassword': function (val) {
      if (this.formData.UserName && this.formData.UserPassword) {
        this.formData.isOk = true
      } else {
        this.formData.isOk = false
      }
    },
  },

  mounted() {
    console.log("login.....", this.$store.state.showLogin);
  }
};
</script>

<style lang="scss" scoped>
.buttom {
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;
  color: rgba(29, 29, 31, 0.5);
  cursor: pointer;
}

.left-style {
  position: absolute;
  cursor: pointer;
}

.backEmi {
  z-index: 999;
  border: none;
  height: 20px;
  width: 21px;
  margin-left: 22px;
}

.backPass {
  z-index: 999;
  border: none;
  height: 20px;
  width: 21px;
  margin-left: 22px;
}

:deep(.el-input__wrapper) {
  border-radius: 61px !important;
  padding: 0;
}

.Login {
  z-index: 100;
  min-width: 412px;
  max-width: 412px;
  position: relative;
}

.close {
  height: 22px;
  width: 22px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.section {
  z-index: 100;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  margin-top: 72px;
  max-width: 362px;
  min-width: 362px;
}

.title {
  margin-bottom: 22px;
}

.titleImg {
  margin-bottom: 16px;
}

.titlelogin {
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1D1D1F;
}

.titlegoR {
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: 6px;
  color: rgba(29, 29, 31, 0.7);
}

.subtitle {
  margin-top: 32px;
  font-size: 14px;
  line-height: 20px;
  color: #1d1d1f;
}

.el-input {
  display: flex !important;
  height: 36px;
  box-sizing: border-box;
  border-radius: 30px;
  display: block;
  flex-direction: row;
}

.setlist {
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(29, 29, 31, 0.3);
  /* background-color: #fafcff; */
}

.Login :deep(.el-input__inner) {
  /* border-radius: 30px; */
  border: 1px solid rgba(29, 29, 31, 0.3);
  height: 36px;
  width: 100%;
  position: initial;
  font-size: 15px;
  color: #303133;
  padding: 8px 4px 8px 10px;


  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* color: rgba(29, 29, 31, 0.3); */
}

/* 自动填充文本样式 */
/* .Login :deep(.el-input__inner:-webkit-autofill::first-line) {
  font-size: 15px;
} */

.pass :deep(.el-input__inner) {
  padding: 0 63px 0 10px;
}

.verificationButton {
  margin-top: 16px;
  float: right;
  height: 46px;
  width: 144px;
  border-radius: 30px;
  background: blue;
  border: 2px solid blue;
}

.unsignUpButton {
  width: 312px;
  height: 36px;
  right: 452px;
  top: 121.42px;

  background: #CCCCD9;
  border-radius: 4px;
  text-align: center;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  color: #FFFFFF;
  cursor: no-drop;
}

.signUpButton {
  width: 312px;
  height: 36px;
  background: #5452F6;
  border-radius: 4px;
  color: #FFFFFF;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: linear-gradient(0deg, #3432D6, #3432D6), #8BD06B;
  }

  &:active {
    background: linear-gradient(0deg, #3432D6, #3432D6), #8BD06B;
  }
}

.goLogIn {
  font-size: 13px;
  line-height: 20px;
  color: #1d1d1f;
  text-align: center;
}

.goLogInButton {
  color: #2f68eb;
  font-size: 13px;
  cursor: pointer;
  text-decoration: underline;
}

.goRules {
  margin-top: 32px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(29, 29, 31, 0.5);
}

.goReset {
  font-size: 13px;
  line-height: 20px;
  color: #606266;
  cursor: pointer;
  text-decoration: underline;
}

.Login :deep(input::-webkit-input-placeholder) {
  font-size: 15px;
  line-height: 20px;
  color: rgba(29, 29, 31, 0.3);
}

.Login :deep(input:-moz-placeholder) {
  font-size: 15px;
  line-height: 20px;
  color: rgba(29, 29, 31, 0.3);
}

.Login :deep(input:-ms-input-placeholder) {
  font-size: 15px;
  line-height: 20px;
  color: rgba(29, 29, 31, 0.3);
}

.Login :deep(.el-input__suffix) {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login :deep(.el-form-item) {
  margin-bottom: 20px;
}

.Login :deep(.el-form-item__error) {
  /* padding-top: 0.6875rem; */
  padding-top: 11px;
  color: #d94a48;
  /* padding-left: 1.0625rem; */
  padding-left: 17px;
}

.eye-style {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 2px;
}

.Login :deep(.el-input__prefix-inner > :last-child) {
  margin-right: 5px;
}

.uneye {
  margin-right: 21px;
  border: none;
  background: url("../../../assets/login/uneye.svg") no-repeat;
  height: 32px;
  width: 32px;
  background-size: 32px 32px;
}

.eyes {
  margin-right: 21px;
  border: none;
  background: url("../../../assets/login/eyes.svg") no-repeat;
  height: 32px;
  width: 32px;
  background-size: 32px 32px;
}

.bottomBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.codeInput {
  width: 298px;
}

.codeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code {
  width: 144px;
  height: 48px;
  border-radius: 8px;
  overflow: hidden;
}

.showPass {
  line-height: 1;
}

.custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}

.custom-loading .circular .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: var(--el-button-text-color);
  stroke-linecap: round;
}
</style>
<style>
.el-form-item__error {
  padding: 0 !important;
  padding-top: 3px !important;
  height: 14px;
  font-family: 'Microsoft YaHei';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #F95353;
}
</style>