<template>
  <div>
    <div v-for="(item, index) in menuList" :key="index" class="menu">
      <div :index="index.toString()" :key="index" @click="change(item, index)" class="menu-item"
        :class="[activeClass == index ? 'menu-active' : '']">
        <div class="menu-item-left" v-show="activeClass == index"></div>
        <img :src="[activeClass == index ? item.img1 : item.img]" alt="">
        <!-- <i :class="item.class" v-cloak></i> -->
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
  <!-- 会员不需要显示 -->
  <!-- -=-={{$store.state.userInfo.vip_expired_time <= nowTime}} {{$store.state.userInfo.vip_expired_time}}-{{nowTime}}  -->
  <div class="bottom" v-if="$store.state.userInfo.vip_expired_time <= nowTime || !$store.state.token">
    <div class="bottom-font">
      <span>{{ $t(`message.DailyFreeTrial`) }}</span><span>{{ $store.state.optCount.remaining }}/{{
        $store.state.optCount.total }}</span>
    </div>
    <div class="bottom-buttom" @click="showPur">
      {{ $t(`message.Upgrade`) }}
    </div>
  </div>
</template>
<script>
import { gaEvent } from "@/utils/gaEvent";
export default {
  data() {
    return {
      pageName: "",
      nowTime: '',
      userInfo: {
        email: '',
        count: 0,
        vip_expired_time: 0,
        used_device_count: 0
      },//用户信息
      menuList: [
        {
          img: require('../../assets/menu/all.svg'),
          img1: require('../../assets/menu/alla.svg'),
          name: 'All Tools',
          class: 'iconfont icon-a-baricon personI'

        },
        {
          img: require('../../assets/menu/rompdf.svg'),
          img1: require('../../assets/menu/rompdfa.svg'),
          name: 'Convert from PDF',
          class: 'iconfont icon-a-baricon-1 personI'

        },
        {
          img: require('../../assets/menu/topdf.svg'),
          img1: require('../../assets/menu/topdfa.svg'),
          name: 'Convert to PDF',
          class: 'iconfont icon-a-baricon-2 personI'

        },
        {
          img: require('../../assets/menu/pdffile.svg'),
          img1: require('../../assets/menu/pdffilea.svg'),
          name: 'Optimize PDF',
          class: 'iconfont icon-a-barico2n personI'
        }
      ],
      activeClass: ''
    }
  },
  mounted() {
    if (this.$store.state.userInfo.vip_expired_time) {
      this.userInfo = this.$store.state.userInfo
      console.log("-=-========");
    }
    this.nowTime = Math.round(new Date().valueOf() / 1000)
    console.log("this.nowTime:", this.nowTime, this.userInfo.vip_expired_time);
  },
  methods: {
    handleOpen() {

    },
    handleClose() {

    },
    change(item, index) {
      this.activeClass = index
      this.$store.commit('changeStateMenuIndex', index)
      console.log("item, index:", this.activeClass);
    },
    showPur() {
      // this.$store.commit('changeIsShowPur', true)
      gaEvent("click_buy", {
        event_category: "Buy",
        event_label: this.$store.state.currentPage,
      });
      window.open("https://www.pdnob.com/purchase/buy-ianypdf.html")

    },
  },
}
</script>
<style lang="scss" scoped>
.menu {
  cursor: pointer;

  .menu-active {
    background: linear-gradient(90deg, #FFFFFF 0%, #EEF5FF 100%);
    padding-left: 0px !important;
    color: #5452F6 !important;
    font-weight: 700 !important;
  }

  .menu-item {
    font-family: 'Microsoft YaHei';
    line-height: 64px;
    height: 64px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(29, 29, 31, 0.7);
    display: flex;
    padding-left: 4px;

    i {
      margin: 0px 8px 20px 23px;
      font-size: 24px;
    }

    img {
      margin: 20px 8px 20px 23px;
      max-width: 24px;
      max-height: 24px;
    }

    &:hover {
      background: linear-gradient(90deg, #FFFFFF 0%, #EEF5FF 100%);
    }

    .menu-item-left {
      width: 4px;
      height: 64px;
      background: #5452F6;
    }
  }
}

.bottom {
  width: 172px;
  position: absolute;
  bottom: 28px;
  left: 24px;
  height: 85px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .bottom-font {
    font-family: 'Microsoft YaHei';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #1D1D1F;
  }

  .bottom-buttom {
    width: 102px;
    height: 28px;
    background: #5452F6;
    border-radius: 80px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;
    /* identical to box height, or 167% */
    color: #F5F5FF;

    &:hover {
      background: #3E3BFF;
    }

    &:active {
      background: #3432D6;
    }
  }

}
</style>